import { React, useEffect, useState } from "react";
import Header from "./Header";
import Chart from "./Charts";
import Chartt from "./ChartWYS";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import {
  Wys_address,
  base_url,
  localhost_api,
  usdt_address,
} from "./web3/config";
import { Toaster, toast } from "react-hot-toast";
import {
  idToAddres,
  stakeWYS,
  wysTOkenApprove,
  getWYSToUSDAmt,
  amtApprove,
  allowance,
  claimwys,
  claimWYSAmt,
  wysPrice,
  getBalanceUser,
  checkUser,
  toppup,
  signClaimMessage,
  stakeUSDt,
  usdtApprove,
  usdt_allowance,
} from "./web3/getWeb3";
import { useDispatch } from "react-redux";
import { setRefAddress } from "./redux/reducer";
import { useSelector } from "react-redux";
import { contract_adress } from "./web3/config";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useAccount } from "wagmi";
import { getRoi, getUserStake } from "./web3/api";
import Calculator from "./Calculator";

const Depositusdt = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [res, setRes] = useState(0);
  const [res2, setRes2] = useState(0);
  const [wys, setWys] = useState(0);
  const [wysusdt, setWysusdt] = useState(0);
  const [formInput, setFormInput] = useState("");
  const [formSelect, setFormSelect] = useState();
  const [famt, setFamt] = useState();
  const [isTopup, setIsTopup] = useState(false);
  const [stakepending, setStakePending] = useState(false);
  const { totalStake } = useSelector((state) => state.wallet);
  const { address } = useAccount();
  const [userWysTokenBalance, setUserWysTokenBalance] = useState(0);
  const [act, setAct] = useState(false);

  const handleInputChange = (e) => {
    setFormInput(e.target.value);
  };

  const handleSelectChange = (e) => {
    setFormSelect(e.target.value);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const { refAddress } = useSelector((state) => state.wallet);
  const wallet = useSelector((state) => state.wallet.walletID);

  //claimwys

  const claimAmt = async () => {
    try {
      const sign = await signClaimMessage(address);
      if (sign) {
        const res = await axios.post(localhost_api + "claim-roi", {
          user: address,
          duration: 12,
          plan: 6,
          amount: 0,
          signature: sign,
          message: address,
        });
        console.log(res.data, res, "res from claim");
        if (res.data.status == 200) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data?.message);
        }
      } else {
        toast.error("Signature failed!");
      }
    } catch (error) {
      toast.error("Error occurred while claiming!", {
        autoClose: 1000,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    wysPrice().then((res) => {
      var amt = Number(res) / 1e18;
      setWys(amt);
    });
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fullAmt = formInput;
        setFamt(fullAmt);
        const wysTousdt = await getWYSToUSDAmt(famt * 1e18);
        let usAmt = Number(wysTousdt) / 1e18;
        setWysusdt(usAmt * 1e18);
      } catch (error) {}
    };

    fetchData(); // Call the async function inside useEffect
  });

  useEffect(() => {
    getBalanceUser(wallet, usdt_address)
      .then((res) => {
        const balance = parseFloat(res.value);

        if (!isNaN(balance)) {
          const truncatedNumber = parseFloat(balance.toFixed(5));
          console.log(truncatedNumber, "::::usddt ");
          setUserWysTokenBalance(truncatedNumber / 1e18);
        } else {
          console.error("Invalid balance value:", res.value);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  async function wysTokenPurchase() {
    try {
      setStakePending(true);
      const registerUSer = await checkUser(wallet);
      const apiregister = await axios.post(localhost_api + "isUserExist", {
        address: wallet,
      });
      console.log(apiregister, apiregister.data?.exist, "res from apiregister");
      const resAPI = await axios.post(localhost_api + "usdt-stake", {
        amount: formInput,
        address: wallet,
        duration: 12,
        planId: 6,
      });

      console.log(resAPI, ":::");

      if (registerUSer && apiregister.data?.exist) {
        if (formInput > 99) {
          try {
            const getBalance = await getBalanceUser(wallet, usdt_address);
            const allowanceWYS = await usdt_allowance(wallet, contract_adress);
            const allowanceAmt = Number(allowanceWYS) / 1e18;

            console.log(Number(getBalance.value) / 1e18);
            if (Number(getBalance.value) / 1e18 >= formInput) {
              if (allowanceWYS >= formInput * 1e18) {
                const res = stakeUSDt(
                  formInput * 1e18,
                  refAddress,
                  formSelect,
                  resAPI?.data?.data
                );
                toast.promise(res, {
                  loading: "farming is pending",
                  success: "Farming Successful 👌",
                  error: "Error In Farming 🤯",
                });
                console.log(res);
              } else {
                const amt_Approve = usdtApprove(
                  contract_adress,
                  formInput * 1e18
                );
                toast
                  .promise(amt_Approve, {
                    loading: "Approving the fUSDT Token",
                    success: "Approving Successful 👌",
                    error: "Approving Failed 🤯",
                  })
                  .then(() => {
                    console.log(refAddress, formSelect, "data of stajke wys");
                    const res = stakeUSDt(
                      formInput * 1e18,
                      refAddress,
                      formSelect,
                      resAPI?.data?.data
                    );
                    console.log(res, "asasass");
                    toast.promise(res, {
                      loading: "Farming is Pending",
                      success: "Farming Successful 👌",
                      error: "Farming Failed 🤯",
                    });
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });

                console.log(amt_Approve);
              }
            } else {
              toast.error(
                "User has insufficient balance to perform the transaction."
              );
            }
          } catch (error) {
            toast.error("Error While Farming");
          }
        } else {
          toast.error("Invalid Amount ! Minimum farming amount is 100");
        }
      } else {
        toast.error("Plese Signup to Stake");
        setTimeout(() => {
          window.location.href = "/signup";
        }, 1000);
      }
      setStakePending(false);
    } catch (err) {
      setStakePending(false);
      console.log(err);
    }
    setStakePending(false);
  }

  const [ref, setRef] = useState(false);
  const [to, setTo] = useState("");

  useEffect(() => {
    const currentURL = window.location.href;
    const splited = currentURL.split("?");
    if (splited.length > 1) {
      console.log("Call if");
      const format = splited[1].split("=");
      const params = new URLSearchParams(new URL(currentURL).search);
      if (format[0] == "refID") {
        const refIDValue1 = format[1].split("&");
        const refIDValue = refIDValue1[0];
        setRef(true);
        setTo(refIDValue);
      }
    }
    // console.log(base_url,"basesese")
  });

  const { walletID, allStakes } = useSelector((state) => state.wallet);
  const [usdtTvl, setUsdtTvl] = useState(0);
  useEffect(() => {
    if (address != "") {
      const tvl = async () => {
        try {
          const response = await axios.get(localhost_api + "total-staked", {
            params: {
              user: address,
            },
          });
          if (response) {
            response.data.data.forEach((item) => {
              const { _id, totalStakedAmt } = item;
              const { planId, duration } = _id;

              if (planId == "6" && duration == 12) {
                setUsdtTvl(Number(totalStakedAmt) / 1e18);
              }
            });
          }

          const freeIdres = await axios.post(localhost_api + "isfreeId", {
            user: wallet,
          });
          if (freeIdres) {
            if (freeIdres?.data?.status == 200) {
              setIsTopup(freeIdres?.data?.topup);
            }
          }
          // console.log(freeIdres,"res from free id");
        } catch (error) {
          console.error("Error fetching total-staked:", error);
        }
      };
      tvl();
    }
  }, [address]);

  //roi
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    getRoi(address, 12, 6)
      .then((res) => {
        console.log(res, "red From getRoi api");
        if (res?.data?.status == 200) {
          setRes(res?.data?.roi);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getRoi(address, 36, 1).then((res) => {
      console.log(res, "red From getRoi api");
      if (res?.data?.status == 200) {
        setRes2(res?.data?.roi);
      }
    });
  }, [address, refresh]);

  return (
    <>
      <Header />
      <Toaster />
      <div className="row col-lg-12" id="w1">
        <div
          className="row col-lg-11"
          style={{
            margin: "auto",
            borderRadius: "21px",
            background: "var(--Gray-900, #171717)",
            height: "auto",
            marginBottom: "20px",
          }}
        >
          <div
            className="col-lg-8"
            style={{ marginTop: "10px", height: "90px" }}
          >
            <Link to={to != "" ? `/app?refID=${to}` : "/app"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M28 15.9999C28 16.2652 27.8946 16.5195 27.7071 16.7071C27.5196 16.8946 27.2652 16.9999 27 16.9999H7.41375L14.7075 24.2924C14.8004 24.3854 14.8741 24.4957 14.9244 24.6171C14.9747 24.7384 15.0006 24.8686 15.0006 24.9999C15.0006 25.1313 14.9747 25.2614 14.9244 25.3828C14.8741 25.5042 14.8004 25.6145 14.7075 25.7074C14.6146 25.8004 14.5043 25.8741 14.3829 25.9243C14.2615 25.9746 14.1314 26.0005 14 26.0005C13.8686 26.0005 13.7385 25.9746 13.6171 25.9243C13.4957 25.8741 13.3854 25.8004 13.2925 25.7074L4.2925 16.7074C4.19952 16.6146 4.12577 16.5043 4.07544 16.3829C4.02512 16.2615 3.99921 16.1314 3.99921 15.9999C3.99921 15.8685 4.02512 15.7384 4.07544 15.617C4.12577 15.4956 4.19952 15.3853 4.2925 15.2924L13.2925 6.29245C13.4801 6.1048 13.7346 5.99939 14 5.99939C14.2654 5.99939 14.5199 6.1048 14.7075 6.29245C14.8951 6.48009 15.0006 6.73458 15.0006 6.99995C15.0006 7.26531 14.8951 7.5198 14.7075 7.70745L7.41375 14.9999H27C27.2652 14.9999 27.5196 15.1053 27.7071 15.2928C27.8946 15.4804 28 15.7347 28 15.9999Z"
                  fill="white"
                />
              </svg>
            </Link>

            <img
              src="image/usdt.png"
              alt=""
              height={32}
              width={45}
              style={{ marginLeft: "5px" }}
            />

            <ul
              style={{
                listStyleType: "none",
                display: "inline-block",
                position: "relative",
                top: "5px",
                left: "-25px",
              }}
            >
              <li
                style={{
                  color: "#FFF",
                  fontFamily: "Satoshi",
                  fontSize: "26px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                fUSDT
              </li>
            </ul>
          </div>

          <div
            className="row col-lg-7"
            style={{ margin: "0px 20px 10px" }}
            id="c"
          >
            <div
              className="row col-lg-12"
              style={{
                margin: "0px auto",
                borderRadius: "12px",
                height: "auto",
                border: "1px solid",
              }}
            >
              <div className="apy">
                <p>APY</p>
                <h6>{(16.67 * 12).toFixed(2) + "%"}</h6>
              </div>
              <div className="apy">
                <p>Daily</p>
                <h6>{((16.67 * 12) / 365).toFixed(2)}%</h6>
              </div>
              <div className="apy">
                <p>TVL</p>
                <h6>{usdtTvl.toFixed(2)} WYS</h6>
              </div>
              <div className="apy">
                <p>My TVL</p>
                <h6>
                  {(allStakes
                    ? allStakes?.length > 0
                      ? allStakes.find(
                          (it) => it.planId == 6 && it.duration == 12
                        )?.ttlAmt > 0
                        ? allStakes.find(
                            (it) => it.planId == 6 && it.duration == 12
                          )?.ttlAmt / 1e18
                        : 0
                      : 0
                    : 0
                  ).toFixed(2)}
                  <span>WYS</span>
                </h6>
              </div>
            </div>
            <div
              className="col-lg-12"
              style={{
                height: "fit-content",
                margin: "20px auto ",
                border: "1px solid",
                borderRadius: "12px",
              }}
            >
              <Chartt />
            </div>
            <div
              className="col-lg-12"
              style={{
                border: "1px solid",
                borderRadius: "12px",
                height: "fit-content",
                margin: "20px 0px ",
              }}
            >
              <table
                className="col-lg-12"
                style={{ margin: " 20px auto" }}
                id="brk"
              >
                <thead>
                  <tr style={{ display: "inline-block" }}>
                    <th
                      style={{
                        color: "#7D7B7B",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                    >
                      APY Breakdown
                    </th>
                  </tr>
                  <tr>
                    <th className="tj">WYS Rewards</th>
                    <th className="td">
                      <span className="td">25%</span>
                    </th>
                  </tr>
                  <tr>
                    <th className="tj">Breakdown Details:</th>
                    <th className="td"></th>
                  </tr>
                  <tr>
                    <th className="tj">APY</th>
                    <th className="td">
                      <span className="td"> 16.67 </span>
                    </th>
                  </tr>
                  <tr>
                    <th className="tj">Reward </th>
                    <th className="td">
                      <span className="td">8.33</span>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div
              className="col-lg-12"
              style={{
                border: "1px solid",
                borderRadius: "12px",
                height: "fit-content",
              }}
            >
              <div className="row col-lg-12">
                <p style={{ color: "#7D7B7B", marginTop: "5px" }}>
                  Farm Details
                </p>

                <p style={{ color: "white" }}>
                  The fUSDT farming pool offers a maximum of 1,000,000 WYS
                  tokens to earn yields up to 16.67% APY with a 3x multiplier.
                  Rewards are paid out in WYS over 12 months. Users deposit WYS
                  and harvest WYS tokens as farming rewards.
                </p>
              </div>
            </div>
          </div>

          <div
            className="row col-lg-4 r1"
            style={{
              borderRadius: "12px",
              border: "1px solid var(--Gray-700, #313131)",
              marginBottom: "20px",
              height: "fit-content",
            }}
          >
            <div className="row tab-header col-lg-7">
              <button
                onClick={() => handleTabClick("tab1")}
                className={activeTab === "tab1" ? "active" : ""}
              >
                Deposit
              </button>
              <button
                onClick={() => {
                  handleTabClick("tab2");
                  setRefresh(!refresh);
                }}
                className={activeTab === "tab2" ? "active" : ""}
              >
                Withdraw
              </button>
            </div>
            <div className="col-lg-3 cp" style={{ textAlign: "end" }}>
              <i
                class="fa-solid fa-calculator calc"
                style={{
                  color: "white",
                  position: "relative",
                  fontSize: "20px",
                  top: "13px",
                  right: "-15px",
                  cursor: "pointer",
                  // zIndex: 999,
                }}
                onClick={() => {
                  setAct(true);
                }}
              ></i>
            </div>
            <div className=" row tab-content">
              {activeTab === "tab1" && (
                <div className=" row col-lg-12" style={{ margin: "20px auto" }}>
                  <div
                    className="col-lg-10"
                    style={{
                      margin: "20px auto",
                      position: "relative",
                      borderRadius: "12px",
                      border: "1px solid #313131",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        display: "inline-block",
                        margin: "10px",
                      }}
                    >
                      Deposit
                    </span>
                    <select
                      href=""
                      style={{
                        color: "white",
                        position: "absolute",
                        right: "5px",
                        margin: "10px",
                      }}
                      id="taba"
                      value={formSelect}
                      onChange={handleSelectChange}
                    >
                      <option value="12" selected>
                        12 month
                      </option>
                    </select>
                    <div id="td">
                      <p id="tabp">
                        <input
                          type="text"
                          placeholder="1000 fUSDT..."
                          id="tabp"
                          name="inputValue"
                          value={formInput}
                          onChange={handleInputChange}
                          style={{
                            border: "1px solid #313131",
                            outline: "none",
                            borderRadius: "6px",
                            color: formInput ? "#e1d31b" : "#7d7b7b",
                          }}
                          className="custom-input"
                        />{" "}
                        <button
                          className="btn-gradient"
                          onClick={() => {
                            setFormInput(userWysTokenBalance);
                          }}
                          style={{
                            borderRadius: "0px",
                            border: "1px solid",
                            borderRadius: "3px",
                          }}
                        >
                          Max
                        </button>
                      </p>
                      <h6 style={{ color: "white", paddingLeft: "12px" }}>
                        Referral Address
                      </h6>
                      <p id="tabp">
                        <input
                          type="text"
                          readOnly
                          placeholder="1000 WYS..."
                          id="tabp"
                          name="referral"
                          value={
                            refAddress?.slice(0, 3) +
                            "..." +
                            refAddress?.slice(-5)
                          }
                          // onChange={handleInputChange}
                          style={{
                            border: "1px solid #313131",
                            outline: "none",
                            borderRadius: "6px",
                            color: formInput ? "#e1d31b" : "#7d7b7b",
                          }}
                          className="custom-input"
                        />{" "}
                      </p>
                      <div
                        className="row col-lg-12"
                        style={{
                          margin: "10px auto",
                          borderRadius: "12px",
                          height: "fit-content",
                          border: "1px solid",
                        }}
                      >
                        <div className="apy apy1" style={{ width: "99%" }}>
                          <p style={{ margin: "0px" }}> fUSDT Token Balance</p>
                          <h6>{userWysTokenBalance.toFixed(7)}</h6>
                        </div>
                      </div>
                      <div
                        className="row col-lg-12"
                        style={{
                          margin: "10px auto",
                          borderRadius: "12px",
                          height: "fit-content",
                          border: "1px solid",
                        }}
                      >
                        <div className="apy" style={{ width: "50%" }}>
                          <p style={{ margin: "0px" }}> Amount</p>
                          <h6>{formInput} fUSDT</h6>
                        </div>
                        <div className="apy" style={{ width: "50%" }}>
                          <p style={{ margin: "0px" }}>
                            {(formInput / wys).toFixed(2)} WYS
                          </p>
                          <h6>{formInput} fUSDT</h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-10"
                    style={{
                      margin: "20px auto",
                      borderRadius: "6px",
                      background:
                        "linear-gradient(90deg, #A2D254 0%, #FFD300 100%)",
                      lineHeight: "2",
                    }}
                  >
                    <a
                      href="javascript:void(0)"
                      style={{
                        color: "black",
                        textAlign: "center",
                        width: "100%",
                        display: "inline-block",
                        fontSize: "25px",
                        fontFamily: "Satoshi",
                      }}
                      onClick={() => {
                        if (!stakepending) {
                          wysTokenPurchase();
                        } else {
                          toast.error(
                            "Please wait for the previous transaction to be completed"
                          );
                        }
                      }}
                    >
                      Deposit
                    </a>
                  </div>
                  <table className="col-lg-10" style={{ margin: "auto" }}>
                    <thead>
                      <tr>
                        <th className="tj">Total Invested</th>
                        <th className="te">
                          {" "}
                          $ {(totalStake * wys).toFixed(1)}
                        </th>
                      </tr>
                      <tr>
                        <th className="tj">Underlying Balance</th>
                        <th className="te">{totalStake.toFixed(2)} WYS</th>
                      </tr>
                      <tr>
                        <th className="tj">Current Price</th>
                        <th className="te">${wys.toFixed(3)}</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              )}

              {activeTab === "tab2" && (
                <div className=" row col-lg-12" style={{ margin: "20px auto" }}>
                  <table className="col-lg-10" style={{ margin: "auto" }}>
                    <thead>
                      <tr>
                        <th className="tj">Balance Available to Withdraw</th>
                        <th className="te"> {res?.toFixed(6)}</th>
                      </tr>
                    </thead>
                  </table>

                  <div
                    className="col-lg-10"
                    style={{
                      margin: "20px auto",
                      borderRadius: "6px",
                      background:
                        "linear-gradient(90deg, #A2D254 0%, #FFD300 100%)",
                      lineHeight: "2",
                    }}
                  >
                    <a
                      href="javascript:void(0)"
                      style={{
                        color: "black",
                        textAlign: "center",
                        width: "100%",
                        display: "inline-block",
                        fontSize: "25px",
                        fontFamily: "Satoshi",
                      }}
                      className="with"
                      onClick={() => claimAmt()}
                    >
                      Withdraw to wallet
                    </a>
                  </div>
                  <table className="col-lg-10" style={{ margin: "auto" }}>
                    <thead>
                      <tr>
                        <th className="tj">Withdrawal Fees</th>
                        <th className="te">Zero Fees</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Calculator act={act} setAct={setAct} />
    </>
  );
};

export default Depositusdt;
