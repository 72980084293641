import React from "react";
import { FaCircle } from "react-icons/fa";

const Footer = () => {
  return (
    <div>
      <hr color="#595959" className="footer-box" />
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-lg-8 col-md-8 fimg"
            style={{ paddingLeft: "50px" }}
          >
            <a className="footer-logo" href="#">
              <img
                src="/image/t Text.svg"
                height="50"
                width="100%"
                alt=""
                className="mb-2"
              />
            </a>
            <div
              style={{
                color: "#7D7B7B",
                fontSize: 16,
                fontFamily: " Clash Grotesk,sans-serif",
                fontWeight: "500",
                wordWrap: "break-word",
                paddingLeft: "5px",
              }}
            >
              © 2024 WYscale | All rights reserved
            </div>
            {/* <div>
              <span
                style={{
                  color: "#7D7B7B",
                  fontSize: 16,
                  fontFamily: " Clash Grotesk,sans-serif",
                  fontWeight: "500",
                  wordWrap: "break-word",
                  paddingLeft: "6px",
                }}
              >
                For Support Contact Us @{" "}
              </span>
              <a className="number" href="tel:+9118001204099">
                <span
                  style={{
                    color: "white",
                    fontSize: 16,
                    fontFamily: "Clash Grotesk, sans-serif",
                    fontWeight: 500,
                    textDecoration: "underline",
                    wordWrap: "break-word",
                  }}
                >
                  18001204099
                </span>
              </a>
            </div> */}
          </div>

          <div className="col-lg-2 col-md-2 col-6  mt-3">
            <div
              style={{
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 4,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: 28,
                  fontFamily: " Clash Grotesk,sans-serif",
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                Explore
              </div>
              <a href="https://wyscale.com/newbies" target="_blank">
                <div
                  style={{
                    color: "#9B9B9B",
                    fontSize: 20,
                    fontFamily: " Satoshi",
                    // fontWeight: "500",
                    opacity: "80%",
                    wordWrap: "break-word",
                  }}
                  className="footer-list"
                >
                  Newbies
                </div>
              </a>
              <a href="https://docs.wyscale.com/" target="_blank">
                <div
                  style={{
                    color: "#9B9B9B",
                    fontSize: 20,
                    fontFamily: " Satoshi",
                    // fontWeight: "500",
                    opacity: "80%",
                    wordWrap: "break-word",
                  }}
                  className="footer-list"
                >
                  Knowledge Base
                </div>
              </a>
              <a href="https://wyscale.com/TermsOfService" target="_blank">
                <a href="https://wyscale.com/strategies" target="_blank">
                  <div
                    style={{
                      color: "#9B9B9B",
                      fontSize: 20,
                      fontFamily: " Satoshi",
                      // fontWeight: "500",
                      opacity: "80%",
                      wordWrap: "break-word",
                    }}
                    className="footer-list"
                  >
                    Strategies
                  </div>
                </a>
                <div
                  style={{
                    color: "#9B9B9B",
                    fontSize: 20,
                    fontFamily: " Satoshi",
                    // fontWeight: "500",
                    opacity: "80%",
                    wordWrap: "break-word",
                  }}
                  className="footer-list"
                >
                  Terms & Services
                </div>
              </a>
              <a href="https://wyscale.com/PrivacyPage" target="_blank">
                <div
                  style={{
                    color: "#9B9B9B",
                    fontSize: 20,
                    fontFamily: " Satoshi",
                    // fontWeight: "500",
                    opacity: "80%",
                    wordWrap: "break-word",
                  }}
                  className="footer-list"
                >
                  Privacy Policy
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-6 mt-3">
            <div
              style={{
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 4,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: 28,
                  fontFamily: " Satoshi",
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                Follow Us
              </div>

              <a href="https://twitter.com/wy_scale" target="_blank">
                <div
                  style={{
                    color: "#9B9B9B",
                    fontSize: 20,
                    fontFamily: " Satoshi",
                    // fontWeight: "500",
                    opacity: "80%",
                    wordWrap: "break-word",
                  }}
                  className="footer-list"
                >
                  Twitter
                </div>
              </a>
              <a href="https://www.instagram.com/wy_scale/" target="_blank">
                <div
                  style={{
                    color: "#9B9B9B",
                    fontSize: 20,
                    fontFamily: " Satoshi",
                    // fontWeight: "500",
                    opacity: "80%",
                    wordWrap: "break-word",
                  }}
                  className="footer-list"
                >
                  Instagram
                </div>
              </a>
              <a href="https://t.me/wyscale" target="_blank">
                <div
                  style={{
                    color: "#9B9B9B",
                    fontSize: 20,
                    fontFamily: " Satoshi",
                    // fontWeight: "500",
                    opacity: "80%",
                    wordWrap: "break-word",
                  }}
                  className="footer-list"
                >
                  Telegram{" "}
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          style={{
            color: "white",
            fontSize: 20,
            fontFamily: " Satoshi",
            // fontWeight: "500",
            wordWrap: "break-word",
            paddingLeft: "50px",
          }}
          className="mt-5 fdis"
        >
          Disclaimer
        </div>
        <div
          style={{
            width: "100%",
            color: "#7D7B7B",
            fontSize: 14,
            fontFamily: " Satoshi",
            // fontWeight: "500",
            wordWrap: "break-word",
            paddingLeft: "50px",
          }}
          className="mb-3 fcon"
        >
          <span className="pe-1">
            <FaCircle size={6} />
          </span>
          There are substantial risks in investing in a Crypto Portfolio.
          Persons interested in investing in a Crypto Portfolio should carefully
          note the following:
          <br />
          <span className="pe-1">
            <FaCircle size={6} />
          </span>
          WYscale is not a broker-dealer, exchange, custodian, or wallet
          provider, and is not intended for frequent trading activity. Investing
          in digital assets is highly speculative and volatile and WYscale is
          only suitable for investors who are willing to bear the risk of loss
          and experience sharp drawdowns.
          <br />
          <span className="pe-1">
            <FaCircle size={6} />
          </span>
          WYscale do not offer tax, financial, accounting or legal advice.
          Carefully consider the risk factors, investment objectives, fees,
          expenses, and other information associated with before making an
          investment decision. All investments are speculative, may be illiquid
          and involve a high degree of risk, uncertainty, and there is risk of
          the complete loss of the investment. There is no guarantee that any
          specific outcome will be achieved. Since each individual's situation
          is unique, a qualified professional should always be consulted before
          making any financial decisions.
          <br />
          <span className="pe-1">
            <FaCircle size={6} />
          </span>
          The information provided within this website within it is not intended
          as an offer to sell, or the solicitation of an offer to buy/invest in
          any digital assets & funds. Such offerings may only be made by
          prospectus and offering documents.
          <br />
          <span className="pe-1">
            <FaCircle size={6} />
          </span>
          WYscale would like to remind you that the data contained in this
          website is not necessarily real-time nor accurate. All
          cryptocurrencies, baskets, ETF, and fund prices are not provided by us
          but rather by market makers, and so prices may not be accurate and may
          differ from the actual market price, meaning prices are indicative and
          not appropriate for trading purposes. Images, charts & stats on this
          page include illustrations of user experiences intended to demonstrate
          the format of WYscale's interface. These images do not reflect actual
          or performance of our recommendations. Past performance is not a
          guarantee of future results.
          <br />
          <span className="pe-1">
            <FaCircle size={6} />
          </span>
          For more complete disclosures and information about how WYscale works,
          please visit our Terms of Service and Help Center.
        </div>
      </div>
    </div>
  );
};

export default Footer;
