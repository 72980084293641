import React from "react";

const Community = () => {
  return (
    <div className="col-lg-12" style={{ marginTop: "40px" }}>
      <div style={{ position: "absolute", right: "0" }} className="com">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="218"
          height="250"
          viewBox="0 0 218 250"
          fill="none"
          style={{ position: "absolute", right: "3px", top: "-40px" }}
        >
          <path
            d="M307 2H115.77C109.921 2 106.996 2 104.738 3.08399C102.484 4.16636 100.666 5.98437 99.584 8.2384C98.5 10.4958 98.5 13.4205 98.5 19.27V79.73C98.5 85.5795 98.5 88.5042 97.416 90.7616C96.3336 93.0156 94.5156 94.8336 92.2616 95.916C90.0042 97 87.0795 97 81.23 97H56.77C50.9205 97 47.9958 97 45.7384 98.084C43.4844 99.1664 41.6664 100.984 40.584 103.238C39.5 105.496 39.5 108.421 39.5 114.27V135.73C39.5 141.579 39.5 144.504 38.416 146.762C37.3336 149.016 35.5156 150.834 33.2616 151.916C31.0042 153 28.0795 153 22.23 153H19.27C13.4205 153 10.4958 153 8.2384 154.084C5.98437 155.166 4.16636 156.984 3.08399 159.238C2 161.496 2 164.421 2 170.27V230.73C2 236.579 2 239.504 3.08399 241.762C4.16636 244.016 5.98437 245.834 8.2384 246.916C10.4958 248 13.4205 248 19.27 248H350.5"
            stroke="#171717"
            stroke-width="3"
          />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="193"
          height="250"
          viewBox="0 0 193 250"
          fill="none"
          style={{ position: "absolute", right: "3px", top: "-20px" }}
        >
          <path
            d="M307 2H115.77C109.921 2 106.996 2 104.738 3.08399C102.484 4.16636 100.666 5.98437 99.584 8.2384C98.5 10.4958 98.5 13.4205 98.5 19.27V79.73C98.5 85.5795 98.5 88.5042 97.416 90.7616C96.3336 93.0156 94.5156 94.8336 92.2616 95.916C90.0042 97 87.0795 97 81.23 97H56.77C50.9205 97 47.9958 97 45.7384 98.084C43.4844 99.1664 41.6664 100.984 40.584 103.238C39.5 105.496 39.5 108.421 39.5 114.27V135.73C39.5 141.579 39.5 144.504 38.416 146.762C37.3336 149.016 35.5156 150.834 33.2616 151.916C31.0042 153 28.0795 153 22.23 153H19.27C13.4205 153 10.4958 153 8.2384 154.084C5.98437 155.166 4.16636 156.984 3.08399 159.238C2 161.496 2 164.421 2 170.27V230.73C2 236.579 2 239.504 3.08399 241.762C4.16636 244.016 5.98437 245.834 8.2384 246.916C10.4958 248 13.4205 248 19.27 248H350.5"
            stroke="#171717"
            stroke-width="3"
          />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="168"
          height="250"
          viewBox="0 0 168 250"
          fill="none"
        >
          <path
            d="M307 2H115.77C109.921 2 106.996 2 104.738 3.08399C102.484 4.16636 100.666 5.98437 99.584 8.2384C98.5 10.4958 98.5 13.4205 98.5 19.27V79.73C98.5 85.5795 98.5 88.5042 97.416 90.7616C96.3336 93.0156 94.5156 94.8336 92.2616 95.916C90.0042 97 87.0795 97 81.23 97H56.77C50.9205 97 47.9958 97 45.7384 98.084C43.4844 99.1664 41.6664 100.984 40.584 103.238C39.5 105.496 39.5 108.421 39.5 114.27V135.73C39.5 141.579 39.5 144.504 38.416 146.762C37.3336 149.016 35.5156 150.834 33.2616 151.916C31.0042 153 28.0795 153 22.23 153H19.27C13.4205 153 10.4958 153 8.2384 154.084C5.98437 155.166 4.16636 156.984 3.08399 159.238C2 161.496 2 164.421 2 170.27V230.73C2 236.579 2 239.504 3.08399 241.762C4.16636 244.016 5.98437 245.834 8.2384 246.916C10.4958 248 13.4205 248 19.27 248H350.5"
            stroke="url(#paint0_linear_3985_2297)"
            stroke-width="3"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3985_2297"
              x1="56.8202"
              y1="125"
              x2="143.945"
              y2="125"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#A2D254" />
              <stop offset="1" stop-color="#FFD300" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div
        className="col-lg-4"
        style={{ textAlign: "center", margin: "auto" }}
        id="comText"
      >
        Join our community <br />
        and meet like-minded people{" "}
      </div>

      <div style={{ position: "relative" }}>
        <div
          className="row col-lg-7 col-md-8"
          style={{ textAlign: "center", margin: "40px auto auto" }}
        >
          <div className="row" id="logo" style={{}}>
            <div className="col-lg-3" style={{ margin: "auto" }}>
              <a href="https://www.xt.com/en/trade/wys_usdt" target="_blank">
                <img src="image/image 2.png" alt="" style={{ width: "100%" }} />
              </a>
            </div>
            <div className="col-lg-3" style={{ margin: "auto" }}>
              <a href="https://bridge.wyscale.com/?tab=Onramp+%2F+Offramp" target="_blank">
                <img src="image/image 1.png" alt="" style={{ width: "100%" }} />
              </a>
            </div>

            <div className="col-lg-3" id="i3" style={{ margin: "auto" }}>
              <a
                href="https://coinmarketcap.com/community/profile/WYscale/"
                target="_blank"
              >
                <span>CoinMarketCap</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <g clip-path="url(#clip0_3985_2292)">
                    <path
                      d="M27.6506 19.1213C27.092 19.4747 26.4346 19.5187 25.936 19.2373C25.3013 18.8773 24.952 18.04 24.952 16.872V13.3813C24.952 11.696 24.2853 10.496 23.1706 10.172C21.2826 9.62133 19.8613 11.932 19.328 12.8013L15.9973 18.2013V11.6013C15.96 10.0813 15.4666 9.17333 14.5306 8.89867C13.9093 8.71867 12.9826 8.79067 12.0813 10.1693L4.61863 22.152C3.6196 20.2554 3.09874 18.1436 3.1013 16C3.1013 8.78133 8.88663 2.90933 15.9973 2.90933C23.1066 2.90933 28.8906 8.78133 28.8906 16C28.896 16.024 28.8933 16.0453 28.8946 16.0707C28.9613 17.4693 28.508 18.5813 27.6506 19.124V19.1213ZM31.7573 16.0013L31.756 15.928C31.716 7.13733 24.6626 0 15.996 0C7.30663 0 0.235962 7.17733 0.235962 16C0.235962 24.8213 7.30663 32 15.996 32C19.984 32 23.7906 30.484 26.712 27.7333C27.292 27.1867 27.3253 26.2667 26.788 25.6787C26.6612 25.5388 26.5078 25.4256 26.3368 25.3458C26.1657 25.266 25.9804 25.2211 25.7918 25.2137C25.6032 25.2064 25.415 25.2368 25.2383 25.3031C25.0616 25.3695 24.8999 25.4704 24.7626 25.6C22.3949 27.8419 19.258 29.0911 15.9973 29.0907C12.1893 29.0907 8.76396 27.4067 6.4013 24.7333L13.132 13.9267V18.9093C13.132 21.3013 14.06 22.0747 14.8386 22.3013C15.6173 22.528 16.8066 22.3733 18.056 20.344C19.3866 18.192 20.756 15.948 22.0866 13.8373V16.872C22.0866 19.1093 22.9826 20.8987 24.544 21.7787C25.952 22.5747 27.7226 22.5027 29.164 21.592C30.9133 20.488 31.8546 18.4533 31.756 16.0013H31.7573Z"
                      fill="#1276EA"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3985_2292">
                      <rect width="32" height="32" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div className="com">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="276"
            height="357"
            viewBox="0 0 276 357"
            fill="none"
            style={{ position: "absolute", left: "0", top: "40px" }}
          >
            <path
              d="M-234 1.99997L63.025 1.99999C79.5936 1.99999 93.025 15.4314 93.025 32L93.025 71.7023C93.025 88.2708 106.456 101.702 123.025 101.702L244 101.702C260.569 101.702 274 115.134 274 131.702L274 325C274 341.569 260.569 355 244 355L-80.5417 355"
              stroke="#171717"
              stroke-width="4"
            />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="301"
            height="357"
            viewBox="0 0 301 357"
            fill="none"
            style={{ position: "absolute", top: "5px" }}
          >
            <path
              d="M-209 1.99997L88.025 1.99999C104.594 1.99999 118.025 15.4314 118.025 32L118.025 71.7023C118.025 88.2708 131.456 101.702 148.025 101.702L269 101.702C285.569 101.702 299 115.134 299 131.702L299 325C299 341.569 285.569 355 269 355L-55.5417 355"
              stroke="#171717"
              stroke-width="4"
            />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="251"
            height="357"
            viewBox="0 0 251 357"
            fill="none"
            style={{ position: "relative", left: "0" }}
            id="s"
          >
            <path
              d="M-259 1.99997L38.025 1.99999C54.5936 1.99999 68.025 15.4314 68.025 32L68.025 71.7023C68.025 88.2708 81.4565 101.702 98.025 101.702L219 101.702C235.569 101.702 249 115.134 249 131.702L249 325C249 341.569 235.569 355 219 355L-105.542 355"
              stroke="url(#paint0_linear_3985_2211)"
              stroke-width="4"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3985_2211"
                x1="169.09"
                y1="178.5"
                x2="42.0899"
                y2="178.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#A2D254" />
                <stop offset="1" stop-color="#FFD300" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Community;
