export const contract_adress = "0x06B5d78b5386A58a276598DFbd6a1Abf5B473709"; // mainet
// export const contract_adress = "0x3225CeEBFd11E89Cb58688Af10D5C2c9c3ca6CcF"; // testnet
export const contract_abi = [
  {
    type: "event",
    name: "EvStake",
    inputs: [
      { type: "address", name: "user", internalType: "address", indexed: true },
      {
        type: "uint256",
        name: "planId",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "wysAmount",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "otherAmt",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "ttlAmt",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "duration",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OwnershipTransferred",
    inputs: [
      {
        type: "address",
        name: "previousOwner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "newOwner",
        internalType: "address",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Paused",
    inputs: [
      {
        type: "address",
        name: "account",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Registration",
    inputs: [
      { type: "address", name: "user", internalType: "address", indexed: true },
      {
        type: "address",
        name: "referrer",
        internalType: "address",
        indexed: true,
      },
      {
        type: "uint256",
        name: "userId",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "referrerId",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Topup",
    inputs: [
      { type: "address", name: "user", internalType: "address", indexed: true },
      {
        type: "uint256",
        name: "wysAmount",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Unpaused",
    inputs: [
      {
        type: "address",
        name: "account",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Withdraw",
    inputs: [
      { type: "address", name: "user", internalType: "address", indexed: true },
      {
        type: "uint256",
        name: "amount",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "ARB",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "ARBPrice",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "BNB",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "BNBPrice",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "Pause",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "TIME_STEP",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "USDT",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "UnPause",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "WYS",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "WYSPrice",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "WYZPrice",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "changeARBPrice",
    inputs: [{ type: "uint256", name: "price", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "changeBNBPrice",
    inputs: [{ type: "uint256", name: "price", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "changeWYSPrice",
    inputs: [{ type: "uint256", name: "price", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "changeWYZPrice",
    inputs: [{ type: "uint256", name: "price", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "pure",
    outputs: [{ type: "bytes32", name: "", internalType: "bytes32" }],
    name: "getHash",
    inputs: [
      { type: "address", name: "_user", internalType: "address" },
      { type: "uint256", name: "nonce", internalType: "uint256" },
      { type: "uint256", name: "duration", internalType: "uint256" },
      { type: "uint256[]", name: "amounts", internalType: "uint256[]" },
      { type: "uint256", name: "planId", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "getNextDepositMinimum",
    inputs: [
      { type: "address", name: "_user", internalType: "address" },
      { type: "uint256", name: "_planId", internalType: "uint256" },
      { type: "uint256", name: "_duration", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "getPackageToWYSAmt",
    inputs: [{ type: "uint256", name: "package", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "getWYSToUSDAmt",
    inputs: [{ type: "uint256", name: "amount", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "idToAddress",
    inputs: [{ type: "uint256", name: "", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "initialize",
    inputs: [
      { type: "address", name: "_arb", internalType: "address" },
      { type: "address", name: "_bnb", internalType: "address" },
      { type: "address", name: "_wys", internalType: "address" },
      { type: "address", name: "_owner", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "isUserExists",
    inputs: [{ type: "address", name: "user", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "pure",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "isValidSignature",
    inputs: [
      { type: "address", name: "signer", internalType: "address" },
      { type: "bytes32", name: "hash", internalType: "bytes32" },
      { type: "uint8", name: "v", internalType: "uint8" },
      { type: "bytes32", name: "r", internalType: "bytes32" },
      { type: "bytes32", name: "s", internalType: "bytes32" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "lastUserId",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "operator",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "owner",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "paused",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      { type: "uint256", name: "farmTokenAmt", internalType: "uint256" },
      { type: "uint256", name: "maxFarmToken", internalType: "uint256" },
      { type: "uint256", name: "apy", internalType: "uint256" },
      { type: "uint256", name: "percentDivider", internalType: "uint256" },
      { type: "uint256", name: "multipler", internalType: "uint256" },
      { type: "uint256", name: "duration", internalType: "uint256" },
    ],
    name: "plans",
    inputs: [
      { type: "uint256", name: "", internalType: "uint256" },
      { type: "uint256", name: "", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "priceOperator",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "registration",
    inputs: [
      { type: "address", name: "_user", internalType: "address" },
      { type: "address", name: "_referal", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "renounceOwnership",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "sendIncome",
    inputs: [
      { type: "address[]", name: "_users", internalType: "address[]" },
      { type: "uint256[]", name: "_amounts", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setOperator",
    inputs: [{ type: "address", name: "_operator", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setPriceOperator",
    inputs: [
      { type: "address", name: "_priceOperator", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setUSDT",
    inputs: [{ type: "address", name: "_usdt", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "payable",
    outputs: [],
    name: "stakeUSDT",
    inputs: [
      { type: "uint8", name: "planId", internalType: "uint8" },
      { type: "uint256[]", name: "amounts", internalType: "uint256[]" },
      { type: "address", name: "_refferal", internalType: "address" },
      { type: "uint256", name: "duration", internalType: "uint256" },
      { type: "uint256", name: "nonce", internalType: "uint256" },
      { type: "uint8", name: "v", internalType: "uint8" },
      { type: "bytes32", name: "r", internalType: "bytes32" },
      { type: "bytes32", name: "s", internalType: "bytes32" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "stakeWYS",
    inputs: [
      { type: "uint256", name: "amount", internalType: "uint256" },
      { type: "address", name: "_refferal", internalType: "address" },
      { type: "uint256", name: "duration", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "stakeWYSAndARB",
    inputs: [
      { type: "uint256", name: "package", internalType: "uint256" },
      { type: "address", name: "_refferal", internalType: "address" },
      { type: "uint256", name: "duration", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "stakeWYSAndBNB",
    inputs: [
      { type: "uint256", name: "package", internalType: "uint256" },
      { type: "address", name: "_refferal", internalType: "address" },
      { type: "uint256", name: "duration", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "payable",
    outputs: [],
    name: "stakeWYSAndWYZ",
    inputs: [
      { type: "uint8", name: "planId", internalType: "uint8" },
      { type: "uint256[]", name: "amounts", internalType: "uint256[]" },
      { type: "address", name: "_refferal", internalType: "address" },
      { type: "uint256", name: "duration", internalType: "uint256" },
      { type: "uint256", name: "nonce", internalType: "uint256" },
      { type: "uint8", name: "v", internalType: "uint8" },
      { type: "bytes32", name: "r", internalType: "bytes32" },
      { type: "bytes32", name: "s", internalType: "bytes32" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "topup",
    inputs: [{ type: "uint256", name: "amount", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "transferOwnership",
    inputs: [{ type: "address", name: "newOwner", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      { type: "uint256", name: "userId", internalType: "uint256" },
      { type: "address", name: "referrer", internalType: "address" },
      { type: "uint256", name: "partnerCount", internalType: "uint256" },
      { type: "uint256", name: "totalDeposit", internalType: "uint256" },
    ],
    name: "users",
    inputs: [{ type: "address", name: "", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "withdrawToken",
    inputs: [
      { type: "address", name: "_token", internalType: "address" },
      { type: "address", name: "_to", internalType: "address" },
      { type: "uint256", name: "_amount", internalType: "uint256" },
    ],
  },
];
export const Wys_address = "0x804075813fc537F284233e88784469ea390c25F2"; //mainnet
export const arb_address = "0x11022812dd72b14d25FA37Bd9FF281628168b9aD"; //mainenet
export const bnb_address = "0x667b37f1683322730Eb2703C4FC009Ec1Eab3611"; //mainnet
export const usdt_address = "0x07605cb4ae205a8c1be537fc7dad5cdcdfe91bd0";
// export const arb_address = "0xF42BF87D363b4eef44840568f70F309f27E7380a";
// export const Wys_address = "0x5E63305B60C3AeF9194a9d799F737479E6a89467";
// 0xF42BF87D363b4eef44840568f70F309f27E7380a,0x2B0c95d8f2CE8788A370E253dcF66Ce9d4137B5F,0x5E63305B60C3AeF9194a9d799F737479E6a89467,0x9CCf0CD809843c239a6B6332985328a8B65dAC7F
// export const bnb_address = "0x2B0c95d8f2CE8788A370E253dcF66Ce9d4137B5F";
// export const bnb_address = "0x2B0c95d8f2CE8788A370E253dcF66Ce9d4137B5F"; //testnet
// export const usdt_address = "0x0fEB3d5509388460fDf4224EB538408D1eea1Eb6"; testnet
export const token_abi = [
  { type: "constructor", stateMutability: "nonpayable", inputs: [] },
  {
    type: "event",
    name: "Approval",
    inputs: [
      {
        type: "address",
        name: "owner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "spender",
        internalType: "address",
        indexed: true,
      },
      {
        type: "uint256",
        name: "value",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Burn",
    inputs: [
      { type: "address", name: "from", internalType: "address", indexed: true },
      {
        type: "uint256",
        name: "value",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Deposit",
    inputs: [
      { type: "address", name: "dst", internalType: "address", indexed: true },
      {
        type: "uint256",
        name: "value",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Mint",
    inputs: [
      { type: "address", name: "to", internalType: "address", indexed: true },
      {
        type: "uint256",
        name: "value",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OwnershipTransferred",
    inputs: [
      {
        type: "address",
        name: "previousOwner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "newOwner",
        internalType: "address",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Transfer",
    inputs: [
      { type: "address", name: "from", internalType: "address", indexed: true },
      { type: "address", name: "to", internalType: "address", indexed: true },
      {
        type: "uint256",
        name: "value",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Withdrawal",
    inputs: [
      { type: "address", name: "from", internalType: "address", indexed: true },
      {
        type: "uint256",
        name: "value",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "DevelopmentAddrs",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "DevelopmentAmnt",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "FarmMintingAddrs",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "FarmMintingAmnt",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "HarvestingAddrs",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "HarvestingAmnt",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "Qtr_mint",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "RewardsAddrs",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "RewardsAmnt",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "allowance",
    inputs: [
      { type: "address", name: "owner", internalType: "address" },
      { type: "address", name: "spender", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "approve",
    inputs: [
      { type: "address", name: "spender", internalType: "address" },
      { type: "uint256", name: "amount", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "balanceOf",
    inputs: [{ type: "address", name: "account", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "burn",
    inputs: [
      { type: "address", name: "from", internalType: "address" },
      { type: "uint256", name: "value", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "burnPercentage",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "configureBurnPercentage",
    inputs: [{ type: "uint8", name: "_newPercentage", internalType: "uint8" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint8", name: "", internalType: "uint8" }],
    name: "decimals",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "decreaseAllowance",
    inputs: [
      { type: "address", name: "spender", internalType: "address" },
      { type: "uint256", name: "subtractedValue", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "payable",
    outputs: [],
    name: "deposit",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "exemptFromBurn",
    inputs: [{ type: "address", name: "account", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "increaseAllowance",
    inputs: [
      { type: "address", name: "spender", internalType: "address" },
      { type: "uint256", name: "addedValue", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "mint",
    inputs: [
      { type: "address", name: "to", internalType: "address" },
      { type: "uint256", name: "value", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "string", name: "", internalType: "string" }],
    name: "name",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "owner",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "removeExemptionFromBurn",
    inputs: [{ type: "address", name: "account", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "renounceOwnership",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "string", name: "", internalType: "string" }],
    name: "symbol",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "totalSupply",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "transfer",
    inputs: [
      { type: "address", name: "to", internalType: "address" },
      { type: "uint256", name: "value", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "transferFrom",
    inputs: [
      { type: "address", name: "sender", internalType: "address" },
      { type: "address", name: "recipient", internalType: "address" },
      { type: "uint256", name: "amount", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "transferOwnership",
    inputs: [{ type: "address", name: "newOwner", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "payable",
    outputs: [],
    name: "withdraw",
    inputs: [{ type: "uint256", name: "value", internalType: "uint256" }],
  },
];

///API IP
export const base_url = window.location.origin;
export const api_url = "https://farming.wyscale.com/api/";
export const localhost_api = "https://farming.wyscale.com/api/";
// export const localhost_api = "http://localhost:8080/api/";
console.log(base_url, "base_url");
