import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
// import { Toaster } from "react-hot-toast";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { checkUser, registerUser } from "./web3/getWeb3";
import { localhost_api } from "./web3/config";
import { duration } from "moment";

const Registration = () => {
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [wallet, setWallet] = useState();
  const [referral, setReferral] = useState();
  const [phone, setPhone] = useState("");
  const { address } = useAccount();
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [stake, setStake] = useState();
  const [ins, setIns] = useState();

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneNumberChange = (value) => {
    setPhone(value);
    console.log(value);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    console.log(params,":paramsl")
    if (params.has("refID")) {
      const refIDValue = params.get("refID");
      setReferral(refIDValue?.trim());
    } else {
    }

    if (params.has("stake")) {
      setStake(true);
    }
    if (params.has("insurance")) {
      setIns(true);
    }
  }, [location.search]);

  useEffect(() => {
    if (address) {
      const formattedAddress = address.slice(0, 5) + "..." + address.slice(-8);
      setWallet(formattedAddress);
    } else {
      setWallet("Connect Your Wallet");
    }
  }, [address]);

  async function isUserRegistered() {
    try {
      const resp = await axios.post(localhost_api + "isUserExist", {
        address: address,
      });
      return resp.data;
    } catch (e) {
      console.log(e, "error in register's checkuser");
      return { exist: true };
    }
  }

  const register = async () => {
    handleCloseModal();
    // Perform input validation
    if (!name || !address || !referral || !phone) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      setLoader(true);
      var toastId = toast.success("Registration In Progress... Please Wait", {
        duration: Infinity,
      });
      const isexist = await isUserRegistered(address);
      if (!isexist?.exist && isexist?.hasOwnProperty("exist")) {
        const resUser = await axios.get(localhost_api + "idToAddress", {
          params: {
            userId: referral,
          },
        });
        const userExist = await checkUser(address);
        if (resUser.data.status == 200) {
          const apiref = resUser.data.data?.user;
          let register;
          if (!userExist) {
            register = await registerUser(address, apiref);
            console.log(register, "register");
          }
          if (register || userExist) {
            const res = await axios.post(localhost_api + "signup", {
              name: name,
              address: address,
              referrerId: referral,
              phone: phone,
            });
            setLoader(false);
            if (res.data.status === 201) {
              toast.success(
                "Registration Successful,Please Wait We are redirecting you"
              );
              toast.dismiss(toastId);
              if (stake) {
                window.location.href = "https://staking.wyscale.com/?tab=Live";
              } else if (ins) {
                window.location.href = "https://insurance.wyscale.com/";
              } else {
                setTimeout(() => {
                  navigate("/app");
                }, 3000);
              }
            } else {
              toast.dismiss(toastId);
              toast.error(res.data.message);
            }
          } else {
            setLoader(false);
            toast.dismiss(toastId);
            toast.error("Register failed!");
          }
        } else {
          setLoader(false);
          toast.dismiss(toastId);
          toast.error("Invalid Referral code");
        }
      } else {
        setLoader(false);
        toast.dismiss(toastId);
        toast.error("This address is already registered!");
      }
    } catch (err) {
      toast.dismiss(toastId);
      setLoader(false);
      console.log(err, "res form register");
    }
  };

  return (
    <>
      <Header />
      <Toaster />
      <div
        className="row col-lg-12 "
        style={{ background: "url(image/banner.png)", padding: "10px" }}
      >
        <div
          className="col-lg-3  form-card"
          style={{ margin: "90px auto 0px" }}
        >
          <div class="col-lg-12">
            <h5 class="text-gradient " style={{ paddingTop: "30px" }}>
              Let's Get Started
            </h5>
            <p class="p-lg">Enter your details to continue</p>
          </div>
          <div className="col-lg-12">
            <form
              action="
                "
            >
              <div className="col-lg-12 form">
                <h6 style={{ color: "white" }}>Name*</h6>
                <input
                  type="text"
                  placeholder="Name*"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
              <h6 style={{ color: "white" }}>Phone*</h6>
              <div
                className="col-lg-12 form1"
                style={{
                  border: "1px solid rgb(49,49,49)",
                  borderRadius: "80px",
                  background: "trasparent",
                  outline: "none ",
                }}
              >
                <PhoneInput
                  defaultCountry="IN"
                  value={phone}
                  onChange={handlePhoneNumberChange}
                  placeholder="Phone*"
                />
              </div>
              <div className={`col-lg-12 form ${address ? "f1" : ""}`}>
                <h6 style={{ color: "white", marginTop: "10px" }}>Wallet*</h6>
                <input
                  type="text"
                  placeholder="Wallet Adress*"
                  value={wallet}
                  readOnly
                  style={{}}
                />
              </div>
              <div className="col-lg-12 form">
                <h6 style={{ color: "white" }}>Referral*</h6>
                <input
                  type="text"
                  placeholder="Refferral*"
                  value={referral}
                  onChange={(e) => {
                    setReferral(e.target.value);
                  }}
                  // readOnly
                />
              </div>

              <a
                className="col-lg-12 form-btn"
                onClick={register}
                href="javascript:void(0)"
              >
                {" "}
                {loader ? (
                  <div
                    class="spinner-border spinner-border-sm mx-1"
                    role="status"
                  ></div>
                ) : null}
                Register
              </a>
            </form>
          </div>
        </div>
      </div>

      {/* <div style={{ widht: "300px", margin: "auto" }}>
       
        {isOpen && (
          <div
            className="modal1"
            style={{
              position: "relative",
              top: "-400px",
              margin: "auto",
              width: "250px",
              background: "white",
              marginBottom: "40px",
              height: "180px",
              borderRadius: "5px",
            }}
          >
            <div
              className="modal-content row"
              style={{
                background: "white",
                flexDirection: "row",
                height: "180px",
                paddingBottom: "10px",
              }}
            >
              <span
                className="close"
                onClick={handleCloseModal}
                style={{ fontSize: "20px", cursor: "pointer" }}
              >
                <i className="fa fa-close" />
              </span>
              <h2></h2>
              <p className="" style={{ fontSize: "18px" }}>
                Your Registration Referral id is :{referral}
              </p>

              <button
                style={{
                  width: "92px",
                  marginLeft: "10px",
                  outline: "none",
                  background: "#ffe569",
                  border: "1px solid black",
                  borderRadius: "5px",
                }}
                onClick={register}
              >
                Continue
              </button>
              <button
                onClick={handleCloseModal}
                style={{
                  width: "92px",
                  marginLeft: "30px",
                  outline: "none",
                  border: "1px solid black",
                  borderRadius: "5px",
                }}
                className="bg-danger text-white"
              >
                Discard
              </button>
            </div>
          </div>
        )}
      </div> */}
    </>
  );
};

export default Registration;
