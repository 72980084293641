import React from "react";
import "./App.css";
import Footer from "./page/Footer";
import Home from "./page/Home";
import Info from "./page/Info";
import Community from "./page/Community";
import Harvest from "./page/Harvest";
import Header from "./page/Header";

const App = () => {
  return (
    <div style={{ background: "url(image/banner.png)" }}>
      <div className="container-fluid" >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="434"
          height="270"
          viewBox="0 0 434 270"
          fill="none"
          style={{ position: "absolute", right: "0" }}
        >
          <path
            d="M2 -65V143.947C2 160.515 15.4315 173.947 32 173.947H143C159.569 173.947 173 187.378 173 203.947V238C173 254.569 186.431 268 203 268H482"
            stroke="#232222"
            stroke-width="4"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="344"
          height="369"
          viewBox="0 0 344 369"
          fill="none"
          style={{ position: "absolute", right: "0" }}
        >
          <path
            d="M2 -46V337.5C2 354.069 15.4315 367.5 32 367.5H484"
            stroke="url(#paint0_linear_3985_2202)"
            stroke-opacity="0.6"
            stroke-width="3"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3985_2202"
              x1="2.50002"
              y1="-46"
              x2="395"
              y2="367"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#767676" />
              <stop offset="0.504166" stop-color="#929292" stop-opacity="0" />
              <stop offset="1" stop-color="#767676" />
            </linearGradient>
          </defs>
        </svg>

        <Header />
        <Home />
        <Info />
        <Community />
        <Harvest />
        <Footer />
      </div>
    </div>
  );
};

export default App;
