import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import Chart from "chart.js/auto";

const ChartBNB = () => {
  const [chartDataWYS, setChartDataWYS] = useState({
    labels: [],
    datasets: [
      {
        label: "WYS Price",
        fill: false,
        lineTension: 0.2,
        backgroundColor: "yellow",
        borderColor: "yellow",
        borderWidth: 2,
        pointBorderColor: "yellow",
        pointBackgroundColor: "yellow",
        pointBorderWidth: 3,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "white",
        pointHoverBorderColor: "yellow",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        pointStyle: "circle",
        data: [],
      },
    ],
  });

  const [chartDataBNB, setChartDataBNB] = useState({
    labels: [],
    datasets: [
      {
        label: "BNB Price",
        fill: false,
        lineTension: 0.2,
        backgroundColor: "green",
        borderColor: "green",
        borderWidth: 2,
        pointBorderColor: "green",
        pointBackgroundColor: "green",
        pointBorderWidth: 3,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "white",
        pointHoverBorderColor: "green",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        pointStyle: "circle",
        data: [],
      },
    ],
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [range, setRange] = useState("7"); // Default to 7 days

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        // Fetch WYS prices
        const wysResponse = await axios.get(
          "https://api.coingecko.com/api/v3/coins/wyscale/market_chart",
          {
            params: {
              vs_currency: "usd",
              days: range,
            },
          }
        );
        const wysPrices = wysResponse.data.prices;

        // Fetch BNB prices
        const bnbResponse = await axios.get(
          "https://api.coingecko.com/api/v3/coins/binancecoin/market_chart",
          {
            params: {
              vs_currency: "usd",
              days: range,
            },
          }
        );
        const bnbPrices = bnbResponse.data.prices;

        // Transform data into chart format
        const labels = wysPrices.map((price) =>
          new Date(price[0]).toLocaleDateString()
        );
        const wysData = wysPrices.map((price) => price[1]);
        const bnbData = bnbPrices.map((price) => price[1]);

        setChartDataWYS((prev) => ({
          ...prev,
          labels: labels.reverse(),
          datasets: [
            {
              ...prev.datasets[0],
              data: wysData.reverse(),
            },
          ],
        }));

        setChartDataBNB((prev) => ({
          ...prev,
          labels: labels.reverse(),
          datasets: [
            {
              ...prev.datasets[0],
              data: bnbData.reverse(),
            },
          ],
        }));

        setLoading(false);
      } catch (error) {
        console.error("Error fetching prices:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchPrices();
  }, [range]);

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "category",
        border: {
          color: "#313131",
        },
        // grid: {
        //   color: "rgba(200, 200, 200, 0.2)",
        // },
      },
      y: {
        border: {
          color: "#313131",
        },
        // grid: {
        //   color: "rgba(200, 200, 200, 0.2)",
        // },
      },
    },
    elements: {
      point: {
        pointStyle: "circle",
      },
    },
  };

  if (loading) return <div className="text-white">Loading...</div>;
  if (error) return <div>""</div>;

  return (
    <div style={{ width: "100%" }}>
      <div
        className="row col-lg-12"
        style={{
          border: "1px solid",
          borderRadius: "12px",
          height: "fit-content",
          position: "relative",
          margin: "20px 0px",
        }}
      >
        <div className="row col-lg-4 tvl d-none">
          <a href="#apy">APY</a>
          <a href="#tvl">TVL</a>
          <a href="#balance">My Balance</a>
        </div>
        <div
          className="row col-lg-4 y"
          style={{ position: "absolute", right: "5px" }}
        >
          <a
            href="javascript:void(0)"
            onClick={() => setRange("1")}
            style={{ color: `${range == 1 ? "#ead313" : "white"}` }}
          >
            1D
          </a>
          <a
            href="javascript:void(0)"
            onClick={() => setRange("7")}
            style={{ color: `${range == 7 ? "#ead313" : "white"}` }}
          >
            1W
          </a>
          <a
            href="javascript:void(0)"
            onClick={() => setRange("30")}
            style={{ color: `${range == 30 ? "#ead313" : "white"}` }}
          >
            1M
          </a>
          <a
            href="javascript:void(0)"
            onClick={() => setRange("180")}
            style={{ color: `${range == 180 ? "#ead313" : "white"}` }}
          >
            6M
          </a>
          <a
            href="javascript:void(0)"
            onClick={() => setRange("365")}
            style={{ color: `${range == 365 ? "#ead313" : "white"}` }}
          >
            1Y
          </a>
        </div>

        {/* Side-by-side charts */}
        <div className="row col-lg-12" style={{ display: "flex" }}>
          {/* Left chart for WYS */}
          <div className="col-lg-6 mt-5" style={{ padding: "5px", height: "300px" }}>
            <Line data={chartDataWYS} options={options} />
          </div>
          {/* Right chart for BNB */}
          <div className="col-lg-6 mt-5" style={{ padding: "5px", height: "300px" }}>
            <Line data={chartDataBNB} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartBNB;
