import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";

const Announcement = () => {
  return (
    <div className="announcement-container">
      <div className="announcement-text">
        <Link
          to="message"
          spy={true}
          smooth={true}
          duration={10000}
          offset={-50}
        >
          <img
            src="image/a.png"
            alt=""
            style={{ height: "20px", width: "20px" }}
          />{" "}
          WYZth hodlers as
          <img
            src="image/r.png"
            alt=""
            style={{ height: "20px", width: "20px" }}
          />{" "}
          WYZth will get listed from September 8th, 2024 so
          <span style={{ color: "#bedb3e" }}> $WYZ </span> Staking and Farming
          pools will be paused from 3:00 UTC to 12 UTC.
        </Link>
      </div>
    </div>
  );
};

export default Announcement;
