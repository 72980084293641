import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { duration, months } from "moment";
import { wysPrice } from "./web3/getWeb3";
import { useAsyncError } from "react-router-dom";
import { act } from "react";

function Calculator({ act, setAct }) {
  const pools = [
    {
      name: "WYS",
      duration: 24,
      apr: 3.75,
      multiplier: 2,
      perCent1: 100,
    },
    {  
      name: "WYS",
      duration: 36,
      apr: 4.55,
      multiplier: 4,
      perCent1: 100,
    },
    {
      name: "FUSDT",
      duration: 12,
      apr: 16.67,
      multiplier: 3,
      perCent1: 100,
    },
    {
      name: "WYS:ARB",
      duration: 24,
      apr: 2.5,
      multiplier: 2,
      perCent1: 60,
      perCent2: 40,
    },
    {
      name: "WYS:ARB",
      duration: 36,
      apr: 3.85,
      multiplier: 4,
      perCent1: 60,
      perCent2: 40,
    },
    {
      name: "WYS:BNB",
      duration: 24,
      apr: 2.85,
      multiplier: 2,
      perCent1: 50,
      perCent2: 50,
    },
    {
      name: "WYS:BNB",
      duration: 36,
      apr: 4.1,
      multiplier: 4,
      perCent1: 50,
      perCent2: 50,
    },
    {
      name: "WYS:WYZ",
      duration: 24,
      apr: 1.25,
      multiplier: 2,
      perCent1: 50,
      perCent2: 50,
    },
    {
      name: "WYS:WYZ",
      duration: 36,
      apr: 1.5,
      multiplier: 4,
      perCent1: 50,
      perCent2: 50,
    },
    {
      name: "WYS:WYZ",
      duration: 24,
      apr: 1.25,
      multiplier: 2,
      perCent1: 70,
      perCent2: 30,
    },
    {
      name: "WYS:WYZ",
      duration: 36,
      apr: 1.5,
      multiplier: 4,
      perCent1: 70,
      perCent2: 30,
    },
  ];

  const [selected, setSelected] = useState(0);
  const [realWysPrice, setRealWysPrice] = useState();
  const [farmAmt, setFarmAmt] = useState(0);
  const [totalWys, setTotalWys] = useState(0);
  const [farmWithdraw, setFarmWithdraw] = useState(0);
  const [userPrice, setUserPrice] = useState(0);
  const [percentReturn, setPercentReturn] = useState(0);
  const [totalInvested, setTotalInvested] = useState();
  const [compoundAmount, setCompoundAmount] = useState();
  const [month, setMonth] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [compoundInterest, setCompoundInterest] = useState();
  const [activeTab, setActiveTab] = useState("APY");
  const [team, setTeam] = useState();
  const [level, setLevel] = useState();
  const [totalMemberInTeam, setTotalMemberInTeam] = useState(0);
  const [levelIncome, setLeveIncome] = useState();
  const [teamWithdraw, setTeamWithdraw] = useState();
  const [returnp, setreturnp] = useState(0);

  useEffect(() => {
    wysPrice().then((res) => {
      setRealWysPrice(Number(res) / 1e18);
    });
  }, []);
  // for getting totalInvested without compounding
  const handleTotalWys = (farm) => {
    const t = Number(farm) / realWysPrice;
    setTotalWys(t);
  };
  // for get withdrawn amount
  const handleWithdraw = () => {
    const apr = Number(selected.apr);
    const duration = Number(selected.duration);
    const yearlyApr = apr * duration;
    const monthlyRate = apr / 100;
    let p = Number(totalWys);
    const returnAmt = (p * yearlyApr) / 100 + p;
    let principal = farmAmt;
    setFarmWithdraw(returnAmt);
    const totalAmount = principal * Math.pow(1 + monthlyRate, duration);
    const wysWithCompoundAmt = totalAmount / realWysPrice;
    if (isChecked) {
      setFarmWithdraw(wysWithCompoundAmt);
    }
    console.log(totalAmount);

    const totalInterest = totalAmount - principal;
    setCompoundInterest(totalInterest);
  };

  const handleReturn = () => {
    let t = Number(totalWys);
    const realPriceInDollar = Number(t) * Number(realWysPrice);
    const returnAmt = Number(userPrice) * Number(farmWithdraw); // dollar
    const r =
      (isChecked ? returnAmt + compoundInterest : returnAmt) /
      Number(realPriceInDollar);
    const finalReturn = Number(r) * 100;
    setPercentReturn(Number(finalReturn));
  };

  useEffect(() => {
    if (selected) {
      handleWithdraw();
    }
  }, [selected, totalWys]);

  useEffect(() => {
    handleReturn();
  }, [userPrice, selected?.duration, totalInvested]);

  useEffect(() => {
    handleReturn();
    handleWithdraw();
  }, [isChecked, totalInvested, userPrice, compoundAmount, farmWithdraw]);

  useEffect(() => {
    console.log(totalInvested, Number(compoundAmount), "totalInvested");
  }, [totalInvested]);

  const returnCommision = (level) => {
    let commission = 0;
    if (level == 1) {
      commission = 10;
    } else if (level == 2) {
      commission = 5;
    } else if (level > 2 && level < 6) {
      commission = 1;
    } else {
      commission = 0.5;
    }
    return commission;
  };

  const calculateTeam = async (team, level, userP) => {
    if (team && level) {
      const apr = Number(selected.apr);
      const duration = Number(selected.duration);
      const yearlyApr = apr * duration;
      let p = Number(totalWys);
      const farmWithdraw = (p * yearlyApr) / 100 + p; // wys
      const withAmountIndollar = farmWithdraw * userPrice; // dollar
      let totalMember = 0;
      let totalComission = 0;
      for (var i = 1; i <= level; i++) {
        totalMember = totalMember + Math.pow(team, i);
        var commission = returnCommision(i);
        totalComission =
          totalComission + (totalMember * totalWys * commission) / 100;
      }
      setTotalMemberInTeam(totalMember);
      setLeveIncome(totalComission.toFixed(2)); // wys
      const teamValueInDollar = totalComission.toFixed(2) * userP; // teamBuisnes recieved levelIncome*2
      const totalValue = teamValueInDollar; // teamBuisnes + apy // removed Apy
      const ret = (totalValue / Number(farmAmt)) * 100;

      if (userP == 0) {
        setTeamWithdraw(0);
        setreturnp(0);
      } else {
        setTeamWithdraw(totalValue?.toFixed(2));
        setreturnp(ret.toFixed(2));
      }
      // console.log(
      //   teamValueInDollar,
      //   "teamValue$",
      //   farmWithdraw,
      //   levelIncome,
      //   ":levelIncome",
      //   realWysPrice,
      //   ";realWysPrice"
      // );
    } else {
      setTotalMemberInTeam(0);
      setTeamWithdraw(0);
      setreturnp(0);
      setLeveIncome(0);
    }
  };
  useEffect(() => {
    if (selected.apr) {
      console.log("got it");
      calculateTeam(team, level, userPrice);
    }
  }, [selected.apr]);

  return (
    <>
      {act && (
        <div
          className="row col-lg-12 asmr"
          style={{ padding: "10px", background: "#00000029" }}
        >
          <div
            className="row col-lg-4 cCard position-relative"
            style={{ margin: "90px auto 0px", justifyContent: "space-between" }}
          >
            <span
              style={{
                position: "absolute",
                width: "40px",
                right: "-13px",
                top: "-20px",
                fontSize: "20px",
                color: "white",
                height: "40px",
                borderRadius: "100%",
                background: "#171717",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setAct(false);
              }}
            >
              <i className="fa fa-close"></i>
            </span>
            <div className="row col-lg-12 btab">
              <div
                onClick={() => {
                  setActiveTab("APY");
                  setFarmAmt(0);
                  setTotalWys(0);
                  setUserPrice(0);
                  setTotalMemberInTeam(0);
                  setLeveIncome(0);
                  setreturnp(0);
                  setTeamWithdraw(0);
                  setIsChecked(false);
                }}
                className={`${activeTab == "APY" ? "activeT" : ""}`}
              >
                APY
              </div>
              <div
                onClick={() => {
                  setActiveTab("REF");
                  setFarmAmt(0);
                  setTotalWys(0);
                  setUserPrice(0);
                  setTotalMemberInTeam(0);
                  setLeveIncome(0);
                  setreturnp(0);
                  setTeamWithdraw(0);
                  setIsChecked(false);
                }}
                className={`${activeTab == "REF" ? "activeT" : ""}`}
              >
                Referral
              </div>
            </div>

            <div className="col-lg-6 b1" style={{ marginTop: "10px" }}>
              <div className="row col-lg-12 slct">
                <label htmlFor="plan">Farm Pool</label>
                <select
                  name="pool"
                  id="pool"
                  onChange={(e) => {
                    pools.find((item) => {
                      if (item.apr == e.target.value) {
                        setSelected(item);
                      }
                    });
                  }}
                >
                  <option value={0} selected>
                    Select pool
                  </option>
                  {pools.map((pool) => (
                    <option value={pool.apr}>
                      {`${pool.name} (${pool.duration} month)`}{" "}
                    </option>
                  ))}
                </select>
              </div>

              <div
                className="row col-lg-12 calculatorForm"
                style={{ marginTop: "10px" }}
              >
                <div className="col-lg-12 position-relative">
                  <label htmlFor="plan">Farm Amount</label>
                  <input
                    type="text"
                    placeholder="Enter amount"
                    value={farmAmt > 0 ? farmAmt : ""}
                    onChange={(e) => {
                      setFarmAmt(e.target.value);
                      handleTotalWys(e.target.value);
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "18px",
                      top: "35px",
                      color: "white",
                    }}
                  >
                    USDT
                  </span>
                </div>
                <div className="col-lg-12 position-relative">
                  <label htmlFor="plan">WYS Price (entry)</label>
                  <input
                    type="text"
                    placeholder="WYS Price"
                    value={realWysPrice > 0 ? realWysPrice?.toFixed(2) : ""}
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "18px",
                      top: "35px",
                      color: "white",
                    }}
                  >
                    USDT
                  </span>
                </div>
                <div className="col-lg-12 position-relative">
                  <label htmlFor="plan">Total WYS </label>
                  <input
                    type="text"
                    placeholder=""
                    value={totalWys > 0 ? totalWys.toFixed(2) : ""}
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "18px",
                      top: "35px",
                      color: "white",
                    }}
                  >
                    WYS
                  </span>
                </div>
                {activeTab == "REF" && (
                  <>
                    <div className="col-lg-12 position-relative">
                      <label htmlFor="team">Referrals</label>
                      <input
                        type="text"
                        onChange={(e) => {
                          setTeam(e.target.value);
                          calculateTeam(e.target.value, level, userPrice);
                        }}
                      />
                    </div>
                    <div className="col-lg-12 position-relative">
                      <label htmlFor="level">Stage</label>
                      <input
                        type="text"
                        onChange={(e) => {
                          setLevel(e.target.value);
                          calculateTeam(team, e.target.value, userPrice);
                        }}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="row col-lg-12 calculatorForm">
                <div className="col-lg-12 position-relative">
                  <label htmlFor="plan">WYS Price (exit)</label>
                  <input
                    type="text"
                    placeholder="Enter amount"
                    value={userPrice}
                    onChange={(e) => {
                      setUserPrice(e.target.value);
                      calculateTeam(team, level, e.target.value);
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "18px",
                      top: "35px",
                      color: "white",
                    }}
                  >
                    USDT
                  </span>
                </div>
                {/* <div className="col-lg-12 position-relative">
                <label htmlFor="plan">Farm Withdraw</label>
                <input
                  type="text"
                  placeholder="Claimaible amount"
                  value={farmWithdraw > 0 ? farmWithdraw.toFixed(2) : ""}
                />
                <span
                  style={{
                    position: "absolute",
                    right: "18px",
                    top: "35px",
                    color: "white",
                  }}
                >
                  WYS
                </span>
              </div> */}

                {/* <div className="col-lg-12 position-relative">
                <label htmlFor="plan">Result</label>
                <input
                  type="text"
                  placeholder="Enter amount"
                  value={
                    isChecked
                      ? (userPrice * farmWithdraw).toFixed(2) > 0
                        ? (userPrice * farmWithdraw + compoundInterest).toFixed(
                            2
                          )
                        : 0
                      : (userPrice * farmWithdraw).toFixed(2) > 0
                      ? (userPrice * farmWithdraw).toFixed(2)
                      : 0
                  }
                />
                <span
                  style={{
                    position: "absolute",
                    right: "18px",
                    top: "35px",
                    color: "white",
                  }}
                >
                  USDT
                </span>
              </div> */}
              </div>
            </div>
            <div className="col-lg-6" style={{ marginTop: "10px" }}>
              <div
                style={{ color: "white", fontSize: "18px", marginTop: "5px" }}
              >
                Results
              </div>
              <div
                className="row col-lg-12"
                style={{
                  justifyContent: "space-between",
                  color: "white",
                  display: "flex",
                  marginTop: "20px",
                }}
              >
                {activeTab == "APY" ? (
                  <>
                    <span style={{ width: "fit-content", padding: "0px" }}>
                      Farm APY
                    </span>
                    <span style={{ width: "fit-content", padding: "0px" }}>
                      {farmWithdraw > 0 ? farmWithdraw.toFixed(2) + " WYS" : ""}
                    </span>
                  </>
                ) : (
                  <>
                    {" "}
                    <span style={{ width: "fit-content", padding: "0px" }}>
                      Your team
                    </span>
                    <span style={{ width: "fit-content", padding: "0px" }}>
                      {totalMemberInTeam}
                    </span>
                  </>
                )}
              </div>
              {activeTab == "REF" && (
                <div
                  className="row col-lg-12"
                  style={{
                    justifyContent: "space-between",
                    color: "white",
                    display: "flex",
                    marginTop: "10px",
                  }}
                >
                  <span style={{ width: "fit-content", padding: "0px" }}>
                    Level Income
                  </span>
                  <span style={{ width: "fit-content", padding: "0px" }}>
                    {levelIncome} WYS
                  </span>
                </div>
              )}
              {/* {activeTab == "REF" && (
                <div
                  className="row col-lg-12"
                  style={{
                    justifyContent: "space-between",
                    color: "white",
                    display: "flex",
                    marginTop: "10px",
                  }}
                >
                  <span style={{ width: "fit-content", padding: "0px" }}>
                    APY
                  </span>
                  <span style={{ width: "fit-content", padding: "0px" }}>
                    {levelIncome} WYS
                  </span>
                </div>
              )} */}
              <div
                className="row col-lg-12"
                style={{
                  justifyContent: "space-between",
                  color: "white",
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                <span style={{ width: "fit-content", padding: "0px" }}>
                  Claimable
                </span>
                <span style={{ width: "fit-content", padding: "0px" }}>
                  {activeTab == "REF"
                    ? teamWithdraw
                    : isChecked
                    ? (userPrice * farmWithdraw).toFixed(2) > 0
                      ? (userPrice * farmWithdraw + compoundInterest).toFixed(
                          2
                        ) + " USDT"
                      : 0
                    : (userPrice * farmWithdraw).toFixed(2) > 0
                    ? (userPrice * farmWithdraw).toFixed(2) + " USDT"
                    : 0}
                </span>
              </div>

              {activeTab == "APY" && (
                <div
                  className="row col-lg-12 calculatorForm"
                  style={{ marginTop: "20px", marginBottom: "10px" }}
                >
                  <div
                    className="col-lg-12"
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      padding: "0px",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="cCheck"
                      id="cCheck"
                      style={{
                        width: "14px",
                        position: "relative",
                        top: "1px",
                        cursor: "pointer",
                      }}
                      onChange={(e) => {
                        setIsChecked(e.target.checked);
                      }}
                    />
                    <label
                      htmlFor="cCheck"
                      style={{
                        paddingLeft: "10px",
                        cursor: "pointer",
                        width: "calc (100% - 20px)",
                        position: "relative",
                        top: "5px",
                      }}
                    >
                      With Compounding
                    </label>
                  </div>
                </div>
              )}

              <button
                className="btn btn-gradient"
                style={{ marginBottom: "30px" }}
              >
                {activeTab == "REF"
                  ? returnp + "%"
                  : percentReturn > 0 && userPrice > 0
                  ? percentReturn.toFixed(2) + "% apy"
                  : 0}{" "}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Calculator;
