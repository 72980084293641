import React from "react";
import { Link } from "react-router-dom";

const Harvest = () => {
  return (
    <>
      <div className="row" id="h">
     
        <div
          className="col-lg-8"
          style={{
            margin: "90px auto 20px",
            borderRadius: "20px",
            border: "2px solid var(--Gray-700, #313131)",
            height: "auto",
            position: "relative",
          }}
          
        >
          <div className="row" style={{height:'310px'}}>
            <div
              style={{
                width: "82px",
                height: "82px",
                borderBottomRightRadius: "9px",
                border: "1px solid var(--Gradient, #A2D254)",
                borderLeft: "grey",
                borderTop: "grey",
                position: "absolute",
                left: 0,
              }}
              className="com"
            ></div>
            <div
              className="com"
              style={{
                width: "102px",
                height: "102px",
                borderBottomRightRadius: "9px",
                borderTopLeftRadius: "13px",
              
                borderLeft: "grey",
                borderTop: "grey",
                borderRight: "2px solid var(--Gradient, #313131)",
                borderBottom: "2px solid var(--Gradient, #313131)",
                position: "absolute",
                left: 0,
              }}
            ></div>

            <div
              className="com"
              style={{
                width: "122px",
                height: "122px",
                borderBottomRightRadius: "9px",
                borderTopLeftRadius: "13px",
                //   border: "1px solid var(--Gradient, #313131)",
                borderLeft: "grey",
                borderTop: "grey",
                borderRight: "3px solid var(--Gradient, #313131)",
                borderBottom: "3px solid var(--Gradient, #313131)",
                position: "absolute",
                left: 0,
              }}
            ></div>
            <div
              className="com com1"
              style={{
                width: "144px",
                position: "absolute",
                left: "175px",
                height: "300px",
                padding: "0px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="144"
                height="300"
                viewBox="0 0 144 300"
                fill="none"
                style={{ position: "absolute",zIndex:'-1' }}
              >
                <path
                  opacity="0.6"
                  d="M2 -22V29C2 41.1503 11.8497 51 24 51H58C70.1503 51 80 60.8497 80 73V183.5C80 195.65 89.8497 205.5 102 205.5H120C132.15 205.5 142 215.35 142 227.5V314"
                  stroke="url(#paint0_linear_3985_2310)"
                  stroke-width="3"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_3985_2310"
                    x1="72"
                    y1="-22"
                    x2="72"
                    y2="314"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#313131" />
                    <stop offset="1" stop-color="#313131" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>

            <div
              id="harvest"
              className="col-lg-8"
              style={{
                textAlign: "center",
                // margin: "93px 0px 50px 127px",
                color: "#FFF",
                fontFamily: "Clash Grotesk",
                fontSize: "46px",
                fontStyle: "normal",
                fontWeight: "500",      
                lineHeight: "normal",
                margin: "auto",
                // position:'absolute'
              }}
            >
              Start Harvesting in Minutes.
              <div
                style={{
                  borderRadius: "60px",
                  background:
                    "var(--Primary-Linear-Gradient, linear-gradient(90deg, #A2D254 0%, #FFD300 100%))",
                  margin: "30px auto 10px",
                  width: "200px",
                }}
              >
                <Link
                  to={'/app'}
                  style={{
                    color: "#000",
                    fontFamily: "Satoshi",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                    padding: "0px !important",
                    display: "block",
                    lineHeight: "2",
                  }}
                  id="openapp"
                >
                  Open App{" "}
                  <span>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M7 5V12.25C5.652 12.555 4.418 13.133 3.375 13.969L4.625 15.531C5.86546 14.5356 7.40954 13.9952 9 14C12.879 14 16 17.121 16 21H20.563C20.1993 21.6041 20.0048 22.2949 20 23C20 25.2 21.8 27 24 27C26.2 27 28 25.2 28 23C28 21.973 27.582 21.055 26.937 20.344L28.937 15.375L29 15.187V13H24V10C24 9.434 24.434 9 25 9V7C23.355 7 22 8.355 22 10V13H18.687L15.937 5.656L15.687 5H7ZM9 7H14.313L17.063 14.344L17.313 15H26.906L25.313 19H17.75C16.832 15.004 13.27 12 9 12V7ZM9 15C5.7 15 3 17.7 3 21C3 24.3 5.7 27 9 27C12.3 27 15 24.3 15 21C15 17.7 12.3 15 9 15ZM9 17C11.223 17 13 18.777 13 21C13 23.223 11.223 25 9 25C6.777 25 5 23.223 5 21C5 18.777 6.777 17 9 17ZM9 19C8.46957 19 7.96086 19.2107 7.58579 19.5858C7.21071 19.9609 7 20.4696 7 21C7 21.5304 7.21071 22.0391 7.58579 22.4142C7.96086 22.7893 8.46957 23 9 23C9.53043 23 10.0391 22.7893 10.4142 22.4142C10.7893 22.0391 11 21.5304 11 21C11 20.4696 10.7893 19.9609 10.4142 19.5858C10.0391 19.2107 9.53043 19 9 19ZM24 21C25.117 21 26 21.883 26 23C26 24.117 25.117 25 24 25C22.883 25 22 24.117 22 23C22 21.883 22.883 21 24 21Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </Link>
              </div>
            </div>

            <div
              style={{
                width: "28%",
                display: "inline-block",
                padding: "0",
                position: "absolute",
                right: "8px",
              }}
              className="com"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="144"
                height="150"
                viewBox="0 0 144 150"
                fill="none"
                style={{ position: "absolute", left: "-15px", bottom: "-310px",zIndex:'-1' }}
              >
                <path
                  opacity="0.6"
                  d="M2 0V51C2 63.1503 11.8497 73 24 73H58C70.1503 73 80 82.8497 80 95V205.5C80 217.65 89.8497 227.5 102 227.5H120C132.15 227.5 142 237.35 142 249.5V336"
                  stroke="url(#paint0_linear_3985_2311)"
                  stroke-width="3"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_3985_2311"
                    x1="2"
                    y1="152"
                    x2="-1"
                    y2="2.99999"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#313131" />
                    <stop offset="1" stop-color="#313131" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>

              <div
                style={{
                  width: "84px",
                  height: "94px",
                  borderRadius: "9px",
                  border: "1px solid var(--Gradient, #A2D254)",
                  borderRight: "1px solid #323131",
                  position: "absolute",
                  right: "-8px",
                  top: "85px",
                }}
              >
                {" "}
              </div>
              <div
                style={{
                  width: "70px",
                  height: "128px",
                  borderRadius: "9px",
                  border: "2px solid var(--Gradient, #323131)",
                  borderRight: "1px solid #323131",
                  position: "absolute",
                  right: "-8px",
                  top: "68px",
                }}
              >
                {" "}
              </div>
              <div
                style={{
                  width: "54px",
                  height: "148px",
                  borderRadius: "9px",
                  border: "2px solid var(--Gradient, #323131)",
                  borderRight: "1px solid #323131",
                  position: "absolute",
                  right: "-8px",
                  top: "58px",
                }}
              >
                {" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Harvest;
