import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div
      className=""
      style={{ zIndex: "999", position: "relative", paddingTop: "200px",background: 'radial-gradient(42.42% 58.24% at 50% 46.51%, #343333 0%, rgba(26, 26, 26, 0.00) 100%)' }}
    >
      <div
        className="col-lg-6"
        style={{ color: "white", margin: "auto", textAlign: "center" }}
        id="crypto"
      >
        Grow Your <span>Crypto</span>
      </div>
      <div
        className="col-lg-5"
        style={{ color: "white", margin: "auto", textAlign: "center" }}
        id="link"
      >
        Link Your Wallet, Deposit Any Token, and Allow WYScale to Generate
        Profits on Your Behalf!
      </div>
      <div
        style={{ margin: "auto", textAlign: "center", width: "220px" }}
        id="open"
      >
        <button>
          <Link to="/app" style={{color:'black'}}>
          Open App{" "}
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M7 5V12.25C5.652 12.555 4.418 13.133 3.375 13.969L4.625 15.531C5.86546 14.5356 7.40954 13.9952 9 14C12.879 14 16 17.121 16 21H20.563C20.1993 21.6041 20.0048 22.2949 20 23C20 25.2 21.8 27 24 27C26.2 27 28 25.2 28 23C28 21.973 27.582 21.055 26.937 20.344L28.937 15.375L29 15.187V13H24V10C24 9.434 24.434 9 25 9V7C23.355 7 22 8.355 22 10V13H18.687L15.937 5.656L15.687 5H7ZM9 7H14.313L17.063 14.344L17.313 15H26.906L25.313 19H17.75C16.832 15.004 13.27 12 9 12V7ZM9 15C5.7 15 3 17.7 3 21C3 24.3 5.7 27 9 27C12.3 27 15 24.3 15 21C15 17.7 12.3 15 9 15ZM9 17C11.223 17 13 18.777 13 21C13 23.223 11.223 25 9 25C6.777 25 5 23.223 5 21C5 18.777 6.777 17 9 17ZM9 19C8.46957 19 7.96086 19.2107 7.58579 19.5858C7.21071 19.9609 7 20.4696 7 21C7 21.5304 7.21071 22.0391 7.58579 22.4142C7.96086 22.7893 8.46957 23 9 23C9.53043 23 10.0391 22.7893 10.4142 22.4142C10.7893 22.0391 11 21.5304 11 21C11 20.4696 10.7893 19.9609 10.4142 19.5858C10.0391 19.2107 9.53043 19 9 19ZM24 21C25.117 21 26 21.883 26 23C26 24.117 25.117 25 24 25C22.883 25 22 24.117 22 23C22 21.883 22.883 21 24 21Z"
                fill="black"
              />
            </svg>
          </span>
          </Link>
        </button>
      </div>
      <div style={{height:'400px'}}>
        <div style={{ position: "relative" }}>
          <div className="left-u" style={{ position: "relative!important" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="285"
              height="331"
              viewBox="0 0 285 331"
              fill="none"
              style={{ position: "absolute", zIndex: "-1", top: "78px" }}
            >
              <path
                d="M283 406L283 32C283 15.4315 269.569 2.00002 253 2.00002L-199 1.99998"
                stroke="url(#paint0_linear_3985_2203)"
                stroke-opacity="0.6"
                stroke-width="3"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3985_2203"
                  x1="282.5"
                  y1="406"
                  x2="-100.426"
                  y2="-6.401"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#767676" />
                  <stop
                    offset="0.504166"
                    stop-color="#929292"
                    stop-opacity="0"
                  />
                  <stop offset="1" stop-color="#767676" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="" id="left-b" style={{ width: "153px" }}></div>
        </div>
        <div className="col-lg-12" style={{ position: "relative" }}>
          <div style={{ position: "absolute", right: "0" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="192"
              height="282"
              viewBox="0 0 192 282"
              fill="none"
            >
              <path
                d="M88.356 0H290.533C295.761 0 300 4.23868 300 9.46737V90.9846C300 107.553 286.589 120.985 270.021 120.985H132.292C98.4279 120.985 93.7104 147.569 95.7484 163.296C96.0014 165.248 96.2256 167.208 96.2256 169.177V280C96.2256 296.569 82.7941 310 66.2255 310H30.0966C13.5681 310 0.152758 296.641 0.109386 280.113C-0.0113248 234.113 -0.0914609 174.038 0.199141 166.216C0.649969 154.081 0.199141 123.332 28.1505 120.985C52.9054 118.906 58.6566 104.559 58.356 96.3464V30C58.356 13.4315 71.7874 0 88.356 0Z"
                fill="url(#paint0_linear_3985_2205)"
                fill-opacity="0.8"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3985_2205"
                  x1="47.191"
                  y1="155"
                  x2="122.191"
                  y2="155"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#A2D254" />
                  <stop offset="1" stop-color="#FFD300" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
